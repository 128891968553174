<template>
  <v-card
    :class="
      $vuetify.breakpoint.mdAndUp ? 'card-shadow card-padding' : 'card-shadow'
    "
  >
    <v-card-title
      class="
        pt-0
        px-3
        card-padding
        text-h4 text-typo
        justify-space-between
        border-bottom
      "
    >
      <h2>Nuovo Pezzo</h2>
      <v-spacer></v-spacer>
      <v-btn small icon @click="$emit('chiudiDialogNew')">
        <v-icon size="20" class="text-typo">fas fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-0 d-flex flex-column px-0">
      <v-form
        @submit="submit"
        v-model="formValid"
        ref="form_data"
        autocomplete="off"
        lazy-validation
      >
        <v-card>
          <v-card-text class="pa-6 pt-3 text-center font-size-root">
            <label
              class="label-color font-weight-600 d-block text-left text-h5 mt-2"
              >Seriale</label
            >
            <v-text-field
              hide-details="auto"
              required
              :rules="serialeRules"
              outlined
              background-color="rgba(255,255,255,.9)"
              light
              placeholder="Seriale"
              autocomplete="off"
              v-model="seriale"
              ref="inputSeriale"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
                mb-4
              "
            ></v-text-field>

            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-primary
                py-3
                px-6
                rounded-sm
                my-1
              "
              color="primary"
              type="submit"
              >Crea</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import apiPezzi from "@/js/pages/pezzi";
import { serialeRules, requiredRules } from "@/js/validationRules";

export default {
  name: "newPezzo",
  props: ['id_progetto', 'id_particolare'],
  data() {
    return {
      token: this.$cookies.get("token_goldencar"),
      this_ruolo: this.$store.state.ruolo,
      seriale: "",
      formValid: false,
      serialeRules: [...serialeRules],
      requiredRules: requiredRules,
      loading: false,
      listSerialiParticolare: [],
    };
  },
  async created() {
    this.listSerialiParticolare = await apiPezzi.getAllSerialiParticolare(this.token, this.id_particolare) || [];
    this.serialeRules.push(
      v => !this.listSerialiParticolare.includes(String(v)) || 'Seriale già inserito'
    )
  },
  mounted() {
    let those = this;
    setTimeout(() => {
      if (those.$refs['inputSeriale'])
        those.$refs['inputSeriale'].focus();
    }, 15);
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      if(!this.loading) {
        this.loading = true;
        if (this.$refs.form_data.validate() && this.formValid) {
          let data = {
            id_progetto: this.id_progetto,
            id_particolare: this.id_particolare,
            seriale: this.seriale,
          };
          let newPezzo = await apiPezzi.insertNew(this.token, data);
          if (newPezzo) this.$emit("chiudiDialogNew", newPezzo);
          else alert("Errori durante la creazione, ricontrolla i dati");
        }
        this.loading = false;
      }
    },
  },
};
</script>
