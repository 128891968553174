<template>
    <v-container class="listParticolariOperatore">
        <v-row>
            <v-col cols="12" sm="3" class="text-typo">
                <v-row :class="!$vuetify.breakpoint.mdAndUp ? 'px-3' : ''">
                    <v-col cols="12" class="text-typo pa-0 mt-2">
                        <v-btn
                            elevation="0"
                            :ripple="false"
                            @click="$router.go(-1)"
                            icon
                            fab
                            class="font-weight-600 text-capitalize ma-1"
                            color="grey"
                        ><v-icon x-large>fas fa-arrow-circle-left</v-icon></v-btn
                        >
                    </v-col>
                    <v-col cols="12" class="text-typo pa-0 mt-2">
                        <span class="text-h6 mb-0">Nome Progetto</span>
                        <p
                            class="font-weight-600 text-h3 mb-0 copy_cursor"
                            @click="copyText(progetto['nome'])"
                        >
                            {{ progetto["nome"] }}
                        </p>
                    </v-col>
                    <v-col cols="12" class="text-typo pa-0 mt-2">
                        <p class="text-h6 mb-0">C. Commessa</p>
                        <p
                            class="font-weight-600 text-h3 mb-0 copy_cursor"
                            @click="copyText(progetto['codice_commessa'])"
                        >
                            {{ progetto["codice_commessa"] }}
                        </p>
                    </v-col>
                    <!--v-col cols="6" class="text-typo pa-0 mt-2">
                      <p class="text-h6 mb-0">C. Cliente</p>
                      <p
                        class="font-weight-600 text-h3 mb-0 copy_cursor"
                        @click="copyText(progetto['codice_cliente'])"
                      >
                        {{ progetto["codice_cliente"] }}
                      </p>
                    </v-col-->
                    <v-col cols="12" class="text-typo pa-0 mt-2">
                        <span class="text-h6 mb-0">Nome Particolare</span>
                        <p
                            class="font-weight-600 text-h3 mb-0 copy_cursor"
                            @click="copyText(particolare['nome'])"
                        >
                            {{ particolare["nome"] }}
                        </p>
                    </v-col>
                    <v-col cols="12" class="text-typo pa-0 mt-2">
                        <span class="text-h6 mb-0">Part Number</span>
                        <p
                            class="font-weight-600 text-h3 mb-0 copy_cursor"
                            @click="copyText(particolare['part_number'])"
                        >
                            {{ particolare["part_number"] }}
                        </p>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="9">
                <v-card class="card-shadow">
                    <div class="card-border-bottom pa-3">
                        <p class="font-weight-600 text-h3 text-typo mb-0">Lista Seriali</p>
                    </div>

                    <v-card-text class="px-0 py-0">
                        <v-data-table
                            no-data-text="Ancora nessun dato"
                            :headers="headers"
                            :items="pezzi"
                            :items-per-page="itemsPerPage"
                            must-sort
                            :page="page"
                            :search="search"
                            hide-default-footer
                            class="table thead-light"
                            mobile-breakpoint="0"
                            :options="{
                sortBy: ['percentuale', 'data_completamento'],
                sortDesc: [false, true],
              }"
                            @click:row="apriPezzo"
                            :item-class="
                () => {
                  return 'pointer';
                }
              "
                        >
                            <template v-slot:top>
                                <v-toolbar flat height="65">
                                    <v-row>
                                        <v-col cols="4">
                                            <v-select v-model="selectedYear" :items="years" @change="reloadLista"></v-select>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-text-field
                                                hide-details
                                                class="input-style font-size-input text-light-input placeholder-light input-icon"
                                                style="max-width: 250px"
                                                dense
                                                flat
                                                filled
                                                solo
                                                v-model="search"
                                                placeholder="Cerca"
                                            >
                                                <template slot="prepend-inner">
                                                    <v-icon color="#adb5bd" size=".875rem">fas fa-search</v-icon>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        elevation="0"
                                        :ripple="false"
                                        @click="dialogNew = true"
                                        height="43"
                                        class="
                      font-weight-600
                      text-capitalize
                      btn-primary
                      py-3
                      px-6
                      rounded-sm
                      ms-3
                    "
                                        color="primary"
                                    >Nuovo</v-btn
                                    >
                                </v-toolbar>
                            </template>
                            <template v-slot:item.seriale="{ item }">
                                <h3 class="ml-2">{{ item.seriale }}</h3>
                            </template>
                            <template v-slot:item.data_completamento="{ item }">
                                {{ formatDate(item.data_completamento) }}
                            </template>
                            <template v-slot:item.percentuale="{ item }">
                <span class="me-2 text-h6"
                >{{ parseInt(item.percentuale || 0) }}%</span
                >
                                <v-progress-linear
                                    class="progress-shadow"
                                    :value="item.percentuale"
                                    rounded
                                    background-color="LightGrey"
                                    :color="
                    item.percentuale === 100 && item.data_completamento
                      ? 'success'
                      : 'error'
                  "
                                ></v-progress-linear>
                            </template>
                        </v-data-table>
                        <div class="pa-2 d-flex justify-end">
                            <v-pagination
                                prev-icon="fa fa-angle-left"
                                next-icon="fa fa-angle-right"
                                total-visible="6"
                                class="pagination"
                                color="primary"
                                v-model="page"
                                :length="numberOfPages"
                                circle
                            ></v-pagination>
                        </div>
                    </v-card-text> </v-card
                ></v-col>
        </v-row>
        <v-dialog
            v-model="dialogNew"
            content-class="position-absolute top-0"
            :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
        >
            <NewPezzo
                v-if="dialogNew"
                :id_progetto="id_progetto"
                :id_particolare="id_particolare"
                @chiudiDialogNew="chiudiDialogNew"
            />
        </v-dialog>
    </v-container>
</template>

<script>
import apiProgetti from "@/js/pages/progetti";
import apiPezzi from "@/js/pages/pezzi";
import NewPezzo from "@/components/NewPezzo.vue";
import { momentDate } from "@/js/formatDate";

export default {
    name: "listPezziOperatore",
    data() {
        return {
            token: this.$cookies.get("token_goldencar"),
            ruolo: this.$store.state.ruolo,
            id_progetto: this.$route.query.id_progetto,
            id_particolare: this.$route.query.id_particolare,
            search: "",
            page: 1,
            itemsPerPage: 10,
            headers: [
                {
                    text: "Seriale",
                    align: "start",
                    sortable: true,
                    value: "seriale",
                    width: "20%",
                },
                { text: "Note", value: "note", width: "30%" },
                { text: "Data Compl", value: "data_completamento", width: "25%" },
                { text: "Percentuale", value: "percentuale", width: "25%" },
            ],
            progetto: {},
            particolare: {},
            pezzi: [],
            dialogNew: false,
            listaVistePezzi: [],
            selectedYear: new Date().getFullYear(),
            years: []
        };
    },
    computed: {
        numberOfPages() {
            return Math.ceil(this.pezzi.length / this.itemsPerPage);
        },
    },
    components: {
        NewPezzo,
    },
    async created() {
        const currentYear = new Date().getFullYear();
        const startYear = 2020;
        const years = [];
        for (let year = startYear; year <= currentYear; year++)
            years.push(year);

        years.reverse();
        this.years = years;

        this.Socket.emit("getListaVistePezzi");
        this.Socket.on("listaVistePezzi", (data) => {
            this.listaVistePezzi = data;
        });
        this.reloadLista();
    },
    methods: {
        formatDate(date) {
            if (date) return momentDate(date);
            return "";
        },
        async reloadLista() {
            let progetto = await apiProgetti.getOne(this.token, this.id_progetto);
            let particolare = {};
            if (progetto.particolari)
                particolare =
                    progetto.particolari.find((x) => x["_id"] == this.id_particolare) ||
                    {};
            this.progetto = progetto || {};

            let pezzi = await apiPezzi.getMultiple(this.token, {
                token: this.token,
                year: this.selectedYear,
                _id: particolare["pezzi"]
            });
            for (let pezzo of pezzi)
                pezzo["percentuale"] = await this.calculatePercentage(pezzo);
            particolare["pezzi"] = pezzi;
            this.pezzi = pezzi || [];
            this.particolare = particolare || {};
        },
        apriPezzo(item = {}) {
            if (!this.listaVistePezzi.includes(item["_id"])) {
                //if (this.ruolo === "Amministratore" || !item.data_completamento) {
                this.$router.push({
                    path: "/pezzoOperatore",
                    query: {
                        _id: item["_id"],
                    },
                });
                //} else alert("Impossibile aprire, il pezzo è già stato chiuso!");
            } else
                alert("Impossibile aprire, qualcun'altro sta modificando il pezzo!");
        },
        copyText(value) {
            var textArea = document.createElement("textarea");
            textArea.style.position = "fixed";
            textArea.style.top = 0;
            textArea.style.left = 0;
            textArea.style.width = "2em";
            textArea.style.height = "2em";
            textArea.style.padding = 0;
            textArea.style.border = "none";
            textArea.style.outline = "none";
            textArea.style.boxShadow = "none";
            textArea.style.background = "transparent";
            textArea.value = value;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand("copy");
            } catch (err) {
                console.log("Impossibile copiare nella clipboard", err);
            }

            document.body.removeChild(textArea);
            this.EventBus.$emit("showAlert", ["Testo copiato negli appunti"]);
        },
        chiudiDialogNew(id_nuovo_pezzo) {
            this.dialogNew = false;
            if (id_nuovo_pezzo)
                window.location.href = "/pezzoOperatore?_id=" + id_nuovo_pezzo;
        },
        calculatePercentage(pezzo = {}) {
            let numPezziConformi = 0;
            for (let passo of pezzo["passi"]) {
                if (
                    passo &&
                    passo["conforme"] &&
                    (!passo["requireImage"] ||
                        (passo["requireImage"] && passo["image_name"]))
                )
                    numPezziConformi += 1;
            }
            return (numPezziConformi * 100) / (pezzo["passi"].length || []);
        },
    },
};
</script>